<template>
    <div class="grid grid-cols-1 mt-12 max-w-lg">
        <template v-if="currentStep === 1">
            <div class="mb-12">
                <p class="mb-12 text-gray-500">
                    Here you can login with a email address associated with your payment
                    account to view and adjust your subscription.
                </p>

                <Input
                    :id="'email'"
                    ref="email"
                    v-model="form.email"
                    :error="form?.errors['email']"
                    @keydown.enter.prevent
                    class="mt-1 block w-full"
                    required
                    autofocus
                    :type="'email'"
                    :placeholder="$t('auth.login.email')"
                    :label='"Enter your email address to login"'
                />

                <template v-if="form?.errors?.email">
                    <p class="mt-2 text-gray-400">
                        If you need any further assistance, please reach out to support@producthero.com
                    </p>
                </template>
            </div>

            <Button
                @click="submitOtpRequest"
                :disabled="form.processing"
                :loading="form.processing"
                class="theme-primary size-large"
            >
                <span>Continue</span>

                <Icon icon="arrowRight" />

            </Button>
        </template>

        <template v-else-if="currentStep === 2">
            <div class="mb-12">
                <Input
                    :id="'password'"
                    ref="password"
                    v-model="loginForm.password"
                    :error="loginForm?.errors['password']"
                    @keydown.enter.prevent
                    class="mt-1 block w-full"
                    required
                    autofocus
                    :type="'password'"
                    :placeholder="$t('auth.login.password')"
                    :label="'Enter the one time password sent to your email to continue'"
                />

                <div class="mb-8 mt-2 w-full text-center text-villains font-bold">
                    <template v-if="hasErrors">
                        <div v-for="(error, index) in usePage().props.errors" :key="index">
                            {{ error }}
                        </div>

                        <p class="mt-2 text-gray-400">
                            If you need any further assistance, please reach out to support@producthero.com
                        </p>
                    </template>
                </div>
            </div>

            <Button
                @click="login"
                :disabled="loginForm.processing"
                :loading="loginForm.processing"
                class="theme-primary size-large"
            >
                <span>Login</span>

                <Icon icon="arrowRight" />

            </Button>

            <Button
                @click="submitOtpRequest"
                :disabled="form.processing"
                :loading="form.processing"
                class="theme-tertiary mt-4"
            >
                <span>Send a new one time password</span>

            </Button>

        </template>
    </div>
</template>

<script setup>
    import Input from '@/Components/Input.vue';
    import {useForm, usePage} from '@inertiajs/vue3';
    import Button from '@/Components/Button.vue';
    import Icon from '@/Components/Base/Icon.vue';
    import {computed, ref} from 'vue';

    const currentStep = ref(1);

    const props = defineProps({
        status: String,
        env: String,
    });

    const errors = computed(() => usePage().props.errors);

    const hasErrors = computed(() => Object.keys(errors.value).length > 0);

    const form = useForm({
        email: ''
    });

    const loginForm = useForm({
        email: '',
        password: '',
    });

    const submitOtpRequest = () => {
        form.post(route('otp_request_password'), {
            preserveScroll: true,
            preserveState: true,
            onSuccess: () => {
                loginForm.email = form.email;
                currentStep.value = 2;
            },
        });
    };

    const login = () => {
        loginForm.post(route('otp_login'), {
            preserveScroll: true,
            preserveState: true,
            onError: () => {
                loginForm.password = '';
            },
        });
    };
</script>
